import { io } from "socket.io-client";

const socket = io({
  transports: ["websocket"],
});

socket.on("hello", (data) => {
  console.log(`server says ${data}`);
  // => server says world
});

socket.emit("howdy", "y'all");
